.rate_company_form {
  label,
  p {
    color: #0a3962;
  }
}

.buttons_group {
  display: flex;
  button {
    background: transparent;
    padding: 1rem 2rem;
    margin-left: -2px;
    border: 1px solid #eee;
  }
}
.rate_company_form.text-right {
  .ui.fluid.dropdown {
    text-align: right !important;
  }
  label,
  button,
  .ui.fluid.dropdown.select_form .text,
  input,
  textarea {
    font-family: "Cairo", sans-serif !important;
  }
  .form-check-label {
    margin-right: 5px;
  }
  .dropdown.icon {
    left: 1rem !important;
    right: unset !important;
  }
}

.text-danger {
  text-transform: capitalize;
}
.ar {
  .custom-control-label::before {
    right: -1.5rem;
    left: unset;
  }

  .custom-control-label::after {
    right: -1.5rem;
    left: unset;
  }
  .custom-control {
    padding-right: 1.5rem;
    padding-left: unset;
  }
}

@media only screen and (max-width: 753px) {
  .typs-container {
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 492px) {
  .typs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .typs-container button {
    margin-bottom: 5px !important;
    width: 200px;
  }
}
